import 'slick-carousel';
import moment from 'moment/moment';

jQuery( document ).ready(function($) {
    $('.merch-carousel').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 800,
                settings:{
                    slidesToShow: 1
                }
            }
        ]
    });

    
    // Seated Events
    fetch('https://cdn.seated.com/api/public/v1/artists/b2dc8c46-c9b1-4c09-bad7-4727550517cc/tour-events', {
        headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
            'Authorization': '0badb7ed-f217-4dc8-b944-99fbaf816a0a' 
        }
    })
    .then((response) => response.json())
    .then((data) => {
        console.log(data);  
        const events = $( '#events' );
        let html = '';
        console.log( data );
        if ( data.data.length ) {
            console.log(data.data);
            html+='<div>'
            //AUS, USA/CAN, EU/UK
            for ( let item of data.data ) {
                let event = item.attributes;
                let country = event['venue-country-code'];
                let sortcountry = (country == "US" || country == "CA")?"US":(country == "AU")?"AU":"EU";
                let region = (event['venue-state-or-province']?event['venue-state-or-province']:event['venue-country-code']);
                const location = event['venue-city'] + ', ' + region;
                html += '<div class="event ' + sortcountry + '">';
                html += '<div class="event-date">' + moment( event['starts-at-date-local'] ).format( 'MMM DD Y' ) + '<div>' + (event["additional-info"]?event["additional-info"]:"") + '</div></div>';
                html += '<div class="event-venue">' + event['venue-name'] + '<div class="event-location">' + location + '</div></div>';
                html += '<div class="event-links">';
            
                html += '<a href="' + event['primary-link-url'] + '" target="_blank" class="btn"><span>' + event['primary-button-text'] + '</span></a>';
                if (event['vip-link-url']){
                    html += '<a href="' + event['vip-link-url'] + '" target="_blank" class="btn"><span>' + event['vip-button-text'] + '</span></a>';
                }
                if (event['promoted-link-url']){ 
                    html += '<a href="' + event['promoted-link-url'] + '" target="_blank" class="btn"><span>' + event['promoted-button-text'] + '</span></a>';
                }

                html += '</div>';
                html += '</div>';
            }
            html += '</div>'; 
            events.html( html );
        } else {
            events.html( '<div class="event no-event"><span>No upcoming events.</span></div>' );
        }
    });
    $('.sort-link').on('click', function(e){
        e.preventDefault();
        let filter = "#events ." + $(this).attr('data-country');
        $("#events .event").hide();
        $(filter).show();
    })
});